import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'herald-fe-shared';

import Layout from '~/layout/LayoutMarketing';
import Arrow from '~/icons/Arrow';
import LargeCTA from '~/components/LargeCTA';
import Image from '~/components/Image';

const Styled = styled.article`
  padding: 50px;
  margin: auto;
  text-align: center;
  .text-container {
    max-width: 60rem;
    margin: auto;
  }
  .bios {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin: 8rem auto 10rem auto;
    max-width: 100rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 30px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    .bios {
      grid-template-columns: 1fr;
      margin: 6rem auto;
    }
  }
`;

const StyledBio = styled.div`
  display: flex;
  text-align: left;
  &:hover .avatar {
    filter: none;
  }
  .avatar {
    flex: 0 0 10rem;
    height: 10rem;
    margin-right: 2rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${colors.GRAY_2()};
    box-shadow: ${styles.BOX_SHADOW};
    filter: grayscale();
    transition: 250ms all;
  }
  .text a {
    text-decoration: none;
  }
  .text h3 {
    display: flex;
    align-items: center;
  }
  .text svg {
    margin-left: 10px;
    fill: ${colors.PURPLE_DARK()};
    will-change: transform;
    transition: 250ms all;
  }
  .text h3:hover svg {
    transform: translateX(5px);
  }
  .links a {
    opacity: 0.7;
    transition: 250ms all;
    &:hover {
      opacity: 1;
    }
  }
  .links a .gatsby-image-wrapper {
    width: 1.5rem;
    height: 1.5rem;
  }
  @media screen and (max-width: 400px) {
    flex-flow: column;
    align-items: center;
    .avatar {
      width: 10rem;
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
`;

interface IBioProps {
  avatar: string;
  linkedin: string;
  name: string;
  bio: string;
  twitter: string;
}

const Bio: React.FC<IBioProps> = (props) => (
  <StyledBio>
    <div className="avatar">
      <Image src={props.avatar} />
    </div>
    <div className="text">
      <a href={props.linkedin} target="_blank">
        <h3>
          {props.name} <Arrow />
        </h3>
      </a>
      <p>{props.bio}</p>
      <div className="links">
        <a href={props.twitter} target="_blank">
          <Image src="logos/twitter.png" />
        </a>
      </div>
    </div>
  </StyledBio>
);

const About = () => {
  return (
    <Layout title="About">
      <Styled>
        <div className="text-container">
          <h1 style={{ marginBottom: 10 }}>Always Be Listening.</h1>
          <p className="large section-description" style={{ marginBottom: 40 }}>
            Nilkanth and Jay have spent the better part of their professional
            lives building products as software engineers, and one piece that
            always seems to be missing from the process: the unfiltered voice of
            the customer. Making the most relevant customer feedback accessible
            to product teams can be a challenge, so many teams can only provide
            it via an intermediary filter. This loss in translation can be very
            expensive. We're building Herald to fix that.
          </p>
        </div>
        <div className="bios">
          <Bio
            linkedin="https://www.linkedin.com/in/jayisms/"
            twitter="https://twitter.com/jayisms"
            avatar="headshots/jay.jpg"
            name="Jay A. Patel"
            bio="Jay most recently founded and managed the team that improved Uber’s incident response times by an order-of-magnitude. Prior to that, he was a core engineer for VMWare Log Insight (early at Pattern Insight, then VMWare). Jay completed his PhD in Computer Science from the University of Illinois at Urbana-Champaign."
          />
          <Bio
            linkedin="https://www.linkedin.com/in/nilkanth-patel-75346134/"
            twitter="https://twitter.com/nilkanthjp"
            avatar="headshots/neel.jpg"
            name="Nilkanth Patel"
            bio="Nilkanth began his career working on data visualization projects at The New Yorker and The New York Times. After obtaining M.S. degrees in Computer Science and Journalism from Columbia University, he became a Product Designer at Quantcast and later became the company's first Manager of Frontend Engineering."
          />
        </div>
      </Styled>
      <LargeCTA />
    </Layout>
  );
};

export default About;
